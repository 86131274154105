var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel-content',[_c('div',{staticClass:"panel__content"},[_c('header',{staticClass:"header__actions"},[_c('v-select',{attrs:{"label":"Type de question","items":_vm.questionsType,"value":_vm.question.type,"attach":true,"outlined":""},on:{"input":function($event){return _vm.$emit('update-question-type', { id: _vm.question.id, value: $event})}}}),_c('div',[_c('v-btn',{attrs:{"text":"","fab":""},on:{"click":function($event){return _vm.$emit('delete-question', _vm.question)}}},[_c('question-icon',{attrs:{"size":40,"minus":true}})],1)],1)],1),_c('v-text-field',{attrs:{"label":"Texte de la question","value":_vm.question.question,"outlined":""},on:{"input":function($event){return _vm.$emit('update-question-question', { id: _vm.question.id, value: $event })}}}),_c('v-text-field',{attrs:{"label":"Nom de la question","value":_vm.question.name,"outlined":""},on:{"input":function($event){return _vm.$emit('update-question-name', { id: _vm.question.id, value: $event })}}}),(_vm.hasAnswer(_vm.question))?_c('aside',[_c('p',[_vm._v(" Réponses : "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.$emit('add-answer', _vm.question)}}},[_vm._v("mdi-plus")])],1),_c('ul',_vm._l((_vm.question.answers),function(answer,index){return _c('admin-form-question-answer',{key:index,attrs:{"answer":answer},on:{"delete-answer":function($event){return _vm.$emit('delete-answer', {question: _vm.question, answer: $event})},"update-answer-text":function($event){return _vm.$emit(
                                        'update-answer-text',
                                        { questionId: _vm.question.id, ...$event}
                                        )},"update-answer-value":function($event){return _vm.$emit(
                                        'update-answer-value',
                                        { questionId: _vm.question.id, ...$event}
                                        )}}})}),1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }