<template>
  <v-expansion-panel-content>
    <div class="panel__content">
      <header class="header__actions">
        <v-select label="Type de question"
                  :items="questionsType"
                  :value="question.type"
                  :attach="true"
                  @input="$emit('update-question-type', { id: question.id, value: $event})"
                  outlined
        />
        <div>
          <v-btn @click="$emit('delete-question', question)" text fab>
            <question-icon :size="40" :minus="true"/>
          </v-btn>
        </div>
      </header>
      <v-text-field label="Texte de la question"
                    :value="question.question"
                    @input="$emit('update-question-question', { id: question.id, value: $event })"
                    outlined
      />
      <v-text-field label="Nom de la question"
                    :value="question.name"
                    @input="$emit('update-question-name', { id: question.id, value: $event })"
                    outlined
      />

      <aside v-if="hasAnswer(question)">
        <p>
          Réponses :
          <v-spacer/>
          <v-icon @click="$emit('add-answer', question)">mdi-plus</v-icon>
        </p>

        <ul>
          <admin-form-question-answer v-for="(answer, index) in question.answers"
                                      :key="index"
                                      :answer="answer"
                                      @delete-answer="$emit('delete-answer', {question, answer: $event})"
                                      @update-answer-text="$emit(
                                          'update-answer-text',
                                          { questionId: question.id, ...$event}
                                          )"
                                      @update-answer-value="$emit(
                                          'update-answer-value',
                                          { questionId: question.id, ...$event}
                                          )"
          />
        </ul>
      </aside>
    </div>
  </v-expansion-panel-content>
</template>

<script>
export default {
  name: 'AdminFormQuestion',

  components: {
    AdminFormQuestionAnswer: () => import('@/components/Survey/QuestionAnswer'),
    QuestionIcon: () => import('@/components/Survey/QuestionIcon'),
  },

  props: {
    question: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    questionsType: [
      {
        text: 'Date',
        value: 'date',
      },
      {
        text: 'Heure',
        value: 'hour',
      },
      {
        text: 'Question à choix multiple',
        value: 'checkbox',
      },
      {
        text: 'Question à choix unique',
        value: 'radio',
      },
    ],
  }),

  methods: {
    hasAnswer ({ type }) {
      return type === 'checkbox' || type === 'radio'
    },
  },
}
</script>

<style lang="scss" scoped>
@import "headerActions";

.panel__content {
  padding-top: 20px;

  aside {
    p {
      display: flex;
      align-items: center;
      font-size: 25px;
    }
  }
}
</style>
